<template>
  <div>
    <a-drawer :title="`${form.id ? '编辑' : '添加'}业务员`" :width="720" :visible="visible"
      :body-style="{ paddingBottom: '80px' }" :footer-style="{ textAlign: 'right' }" @close="onClose">
      <a-form :model="form" :rules="rules" layout="vertical">
        <a-row :gutter="16">

          <a-col :span="24">
            <a-form-item label="所属区域" name="area">
              <a-cascader v-model:value="form.area" :options="areaList" placeholder="请选择" />
            </a-form-item>
          </a-col>
          
          <a-col :span="24">
            <a-form-item label="名称" name="name">
              <a-input v-model:value="form.name" placeholder="请输入" />
            </a-form-item>
          </a-col>
          <a-col :span="24">
            <a-form-item label="手机" name="mobile">
              <a-input v-model:value="form.mobile" placeholder="请输入" />
            </a-form-item>
          </a-col>

          <block v-if="!form.id">
          <a-col :span="24">
            <a-form-item label="队长手机号" name="captainmobile">
              <a-input v-model:value="form.captainmobile" placeholder="请输入" />
            </a-form-item>
          </a-col>

          <a-col :span="24">
            <a-form-item label="是否队长" name="is_captain">
              <a-select v-model:value="form.is_captain" placeholder="请选择">
                <a-select-option value="1">是</a-select-option>
                <a-select-option value="0">否</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          </block>
          <!-- <a-col :span="12">
            <a-form-item label="分类说明" name="description">
              <a-input v-model:value="form.description" placeholder="请输入" />
            </a-form-item>
          </a-col> -->
        </a-row>
      </a-form>
      <template #extra>
        <a-space>
          <a-button @click="onClose">取消</a-button>
          <a-button :loading="createLoading" type="primary" @click="onConfirm">提交</a-button>
        </a-space>
      </template>
    </a-drawer>
  </div>
</template>
<script>
//

import service from '@/service/service';
//import UploadFile from '@/components/common/uploadFile.vue';
import { message} from 'ant-design-vue';




const rules = {
  
};
export default {
  components: {
     //UploadFile
  },
  props: ['current'],
  data() {
    return {
      rules,
      form: {
        id: '',
        name:"",
        mobile:"",
        is_captain:0,
        area:[
          "zhejiang"
        ]
        
      },
      visible: false,
      createLoading: false,
      areaList : [
      {
        value: 'zhejiang',
        label: '浙江省',
        children: [
          {
            value: 'hangzhou',
            label: '杭州市',
          },
          {
            value: 'ningbo',
            label: '宁波市',
          },
          {
            value: 'wenzhou',
            label: '温州市',
          },
          {
            value: 'jiaxing',
            label: '嘉兴市',
          },
          {
            value: 'huzhou',
            label: '湖州市',
          },
          {
            value: 'shaoxing',
            label: '绍兴市',
          },
          {
            value: 'jinhua',
            label: '金华市',
          },
          {
            value: 'quzhou',
            label: '衢州市',
          },
          {
            value: 'zhoushan',
            label: '舟山市',
          },
          {
            value: 'taizhou',
            label: '台州市',
          },
          {
            value: 'lishui',
            label: '丽水市',
          },
        ],
      },
    ],
    }
  },
  watch: {
    current: {
      handler(nowCurrent) {
        if (nowCurrent) {
          let temp = {};
          for (let key in this.form) {
            temp[key] = nowCurrent[key];
          }
          this.form = temp;
          //console.log("bbbbbb")
          //console.log(this.form)
        }
      },
      immediate: true
    }
  },
  methods: {
    onClose() {
      this.visible = false;
      this.onReset();
      this.$emit('close');
    },
    onReset() {
      let temp = {};
      for (let key in this.form) {
        temp[key] = '';
      }
      this.form = temp;
    },
    onShow() {
      this.visible = true;
    },
    async onConfirm() {
      this.createLoading = true;
      console.log(this.form);

      const api = this.form.id ? 'edit_salesman' : 'add_salesman';
      const { code, msg } = await service[api]({
        ...this.form
      })
      this.createLoading = false;
      if (code && code == '1') {
        message.success(msg || '成功');
        this.$emit('refreshList');
        this.onClose();
      }
    }
  },

}
</script>